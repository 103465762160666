/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #1a1a1a;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.navbar {
  background-color: #282c34;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .logo img {
  height: 40px;
}

.navbar nav ul {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.navbar nav ul li {
  display: inline;
}

.navbar nav ul li a {
  color: #61dafb;
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.box {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  margin-bottom: 20px;
}

.box img {
  max-width: 100%;
  height: auto;
}

.btn-dark-blue {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-dark-blue a {
  color: #fff;
  text-decoration: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 90%;
    margin-bottom: 20px;
  }
}
